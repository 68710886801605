import { useState } from 'react'
import ModalVideo from 'react-modal-video'

const YouTubePopup = ({ children, videoId }) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <a
        className="link"
        style={{ cursor: 'pointer' }}
        onClick={() => setOpen(!open)}
      >
        {children}
      </a>
      <ModalVideo
        isOpen={open}
        start="0"
        videoId={videoId}
        onClose={() => setOpen(!open)}
      />
    </>
  )
}

export default YouTubePopup
